<template>
  <footer class="page__footer-wrapper">
    <div class="footer__inner">
      <div class="website-info">
        <div class="link-list">
          <span class="link-item">关于我们</span>
          <span class="link-item">使用条款</span>
          <span class="link-item">友情条款</span>
          <span class="link-item">一网通办</span>
          <span class="link-item">随申办市民云</span>
        </div>
        <p class="copyright">
          Copyright © 2019-2020 data.sh.gov.cn All Rights Reserved<br />
          沪公网安备 31011502006451号 沪ICP备 12038785号， 沪ICP备
          14026724-5号， 网站标识码：3100000101 网站访问量：
          2774317，联系邮箱：sjkf@shanghai.gov.cn
        </p>
      </div>
      <div class="contact-info">
        <div class="tel">
          联系方式：<br />
          117 264 13335
        </div>
        <div class="addr">
          邮箱地址：<br />
          ************************************
        </div>
      </div>
      <div class="footer__qrcode">
        <div class="qrcode__title">校网办二维码</div>
        <div class="qrcode__img">
          <img src="@/assets/images/qrcode.png" />
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.page__footer-wrapper {
  height: 200px;
  width: 100%;
  background: #282828;
  color: #989898;

  .footer__inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .website-info {
      flex: 1;
      margin-top: 40px;
      margin-right: 30px;
      .link-list {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        line-height: 22px;

        .link-item {
          padding: 0 20px;

          &:not(:last-of-type) {
            &:after {
              content: "|";
              display: inline-block;
              position: relative;
              left: 20px;
            }
          }

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }
      }

      .copyright {
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
        margin-top: 38px;
        margin-bottom: 0;
      }
    }

    .contact-info {
      margin-top: 40px;
      margin-right: 30px;
      font-size: 16px;
      line-height: 30px;
    }

    .footer__qrcode {
      text-align: center;
      margin-top: 20px;

      .qrcode__title {
        color: #989898;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 8px;
      }

      .qrcode__img {
        width: 125px;
        height: 125px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
