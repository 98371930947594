<template>
  <div class="page__wrapper">
    <slot name="header">
      <layout-header :selected-nav="selectedNav"></layout-header>
    </slot>
    <main class="page__content-wrapper">
      <slot></slot>
    </main>
    <slot name="footer">
      <layout-footer></layout-footer>
    </slot>
  </div>
</template>
<script>
import LayoutHeader from "./header";
import LayoutFooter from "./footer";
export default {
  components: {
    LayoutHeader,
    LayoutFooter
  },
  props: {
    selectedNav: {
      type: String,
      default: "index"
    }
  }
};
</script>
<style lang="scss" scoped>
.page__wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 70px;

  .page__content-wrapper {
    flex: 1;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
  }
}
</style>
