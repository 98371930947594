<template>
  <layout selectedNav="applied-learning">
    <div class="container">
      <div class="content">
        <div class="breadCrumb">
          <span class="breadCrumb-title">
            <el-divider direction="vertical"></el-divider
            ><span style="margin-left:5px">讲座视频</span>
          </span>
          <span class="more"
            >查看更多
            <i
              class="el-icon-d-arrow-right"
              style="width: 13px;height: 14px;"
            ></i>
          </span>
        </div>
        <div class="videoList">
          <div
            class="videoDetail"
            v-for="(item, index) in videoDetail"
            :key="index"
            @click="linkTo(index)"
          >
            <div class="img">
              <img
                :src="item.imgUrl"
                style="object-fit: fill; width: 285px;height: 175px;"
              />
              <div class="imgTextBox">
                <span class="imgContent">{{ item.title }}</span>
              </div>
            </div>
            <div class="detail">
              <el-tag size="mini">{{ item.tag }}</el-tag>
              <span class="playback">
                <img :src="item.iconUrl" />
                <span class="times">{{ item.playback }}播放量</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="report">
        <div class="breadCrumb">
          <span class="breadCrumb-title">
            <el-divider direction="vertical"></el-divider>
            <span style="margin-left:5px">报告文件</span>
          </span>
          <span class="more"
            >查看更多
            <i
              class="el-icon-d-arrow-right"
              style="width: 13px;height: 14px;"
            ></i>
          </span>
        </div>
        <div class="reportList">
          <template>
            <el-table
              :show-header="false"
              :data="tableData"
              style="width: 100%"
              :cell-style="cellStyle"
            >
              <el-table-column :show-overflow-tooltip="true" width="763px">
                <template slot-scope="scope">
                  <div class="point"></div>
                  <span class="reportContent">
                    {{ scope.row.content }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <span class="date" style="font-size: 12px;color: #656565;">{{
                    scope.row.date
                  }}</span>
                  <el-tag size="medium" style="margin-left:17px"
                    >教育科技</el-tag
                  >
                  <el-tag
                    size="medium"
                    style="background: #BD7745;margin-left:20px"
                    >团体机构</el-tag
                  >
                </template>
              </el-table-column>

              <el-table-column width="180px">
                <template slot-scope="scope">
                  <span class="views" style="font-size: 12px;color: #656565;">
                    <img
                      style="display: inline-block;vertical-align: middle;"
                      src="@/assets/images/viewed.png"
                    />
                    {{ scope.row.playback }}浏览量</span
                  >
                  <el-button style="padding:0;" type="text">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "@/views/Layouts";
export default {
  data() {
    return {
      videoDetail: [
        {
          imgUrl: require("../../assets/images/video1.png"),
          title: "大数据情境下决策范式转变与新型研究方法论",
          tag: "教育科技",
          iconUrl: require("../../assets/images/viewed.png"),
          playback: "12453",
          url: "https://v.youku.com/v_show/id_XNTAyMDY5OTc0MA==.html"
        },
        {
          imgUrl: require("../../assets/images/video2.png"),
          title: "大数据分析在经济学和金融学中的应用",
          tag: "教育科技",
          iconUrl: require("../../assets/images/viewed.png"),
          playback: "5567",
          url: "https://v.youku.com/v_show/id_XNTAyMDY5MjQyMA==.html"
        },
        {
          imgUrl: require("../../assets/images/video3.png"),
          title: "大数据环境下的运作管理",
          tag: "教育科技",
          iconUrl: require("../../assets/images/viewed.png"),
          playback: "3422",
          url: "https://v.youku.com/v_show/id_XNTAyMDY5NjkyMA==.html"
        },
        {
          imgUrl: require("../../assets/images/video1.png"),
          title: "大数据情境下决策范式转变与新型研究方法论",
          tag: "教育科技",
          iconUrl: require("../../assets/images/viewed.png"),
          playback: "3452",
          url: "https://v.youku.com/v_show/id_XNTAyMDY5OTc0MA==.html"
        },
        {
          imgUrl: require("../../assets/images/video2.png"),
          title: "大数据分析在经济学和金融学中的应用",
          tag: "教育科技",
          iconUrl: require("../../assets/images/viewed.png"),
          playback: "2255",
          url: "https://v.youku.com/v_show/id_XNTAyMDY5MjQyMA==.html"
        },
        {
          imgUrl: require("../../assets/images/video3.png"),
          title: "大数据环境下的运作管理",
          tag: "教育科技",
          iconUrl: require("../../assets/images/viewed.png"),
          playback: "6785",
          url: "https://v.youku.com/v_show/id_XNTAyMDY5NjkyMA==.html"
        },
        {
          imgUrl: require("../../assets/images/video1.png"),
          title: "大数据情境下决策范式转变与新型研究方法论",
          tag: "教育科技",
          iconUrl: require("../../assets/images/viewed.png"),
          playback: "7267",
          url: "https://v.youku.com/v_show/id_XNTAyMDY5OTc0MA==.html"
        },
        {
          imgUrl: require("../../assets/images/video2.png"),
          title: "大数据分析在经济学和金融学中的应用",
          tag: "教育科技",
          iconUrl: require("../../assets/images/viewed.png"),
          playback: "8267",
          url: "https://v.youku.com/v_show/id_XNTAyMDY5MjQyMA==.html"
        }
      ],
      tableData: [
        {
          content: "受地理位置和民俗民风影响，历年来信访和历史积案相对较多",
          date: "2020.09.11",
          playback: "1123"
        },
        {
          content: "受地理位置和民俗民风影响，历年来信访和历史积案相对较多",
          date: "2020.09.11",
          playback: "2234"
        },
        {
          content: "受地理位置和民俗民风影响，历年来信访和历史积案相对较多",
          date: "2020.09.11",
          playback: "3211"
        },
        {
          content: "受地理位置和民俗民风影响，历年来信访和历史积案相对较多",
          date: "2020.09.11",
          playback: "5666"
        },
        {
          content: "受地理位置和民俗民风影响，历年来信访和历史积案相对较多",
          date: "2020.09.11",
          playback: "3442"
        }
      ]
    };
  },
  components: {
    Layout
  },
  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    cellStyle() {
      return {
        fontSize: "14px",
        height: "41px",
        padding: "0",
        fontWeight: "500",
        color: "#28292E"
      };
    },
    linkTo(item) {
      window.open(this.videoDetail[item].url, "_blank");
    }
  }
};
</script>
<style lang="scss" scoped>
.el-divider--vertical {
  width: 4px;
  height: 16px;
  line-height: 22px;
  background: #5883f8;
  margin: 0;
}
.el-tag {
  cursor: pointer;
  background-color: #45b5bd;
  display: inline-block;
  height: 20px;
  width: 60px;
  padding: 0;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 2px;
  border: none;
}
.el-button--text {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #5883f8;
  line-height: 20px;
}
.container {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 166px;
  .content {
    margin-top: 40px;
    width: 1202px;
    .breadCrumb {
      display: flex;
      justify-content: space-between;
      .breadCrumb-title {
        height: 22px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      .more {
        height: 20px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #656565;
        line-height: 20px;
      }
    }
    .videoList {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .videoDetail {
        width: 285px;
        margin-bottom: 20px;
        .img {
          position: relative;
          cursor: pointer;
          object-fit: fill;
          margin: 0 0 10px 0;
          width: 285px;
          height: 175px;
          // background-image: linear-gradient(90deg, #9eceff 0%, #56a9ff 100%);
          border-radius: 3px;
          .imgTextBox {
            position: absolute;
            bottom: 0;
            width: 285px;
            height: 30px;
            background: rgba(51, 51, 51, 0.7);
            border-radius: 0px 0px 3px 3px;
            display: flex;
            align-items: center;
            .imgContent {
              display: -webkit-box;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              margin-left: 7px;
              width: 285px;
              height: 20px;
              font-size: 14px;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
        .detail {
          display: flex;
          justify-content: space-between;
          .playback {
            img {
              display: inline-block;
              vertical-align: middle;
              margin-right: 4px;
              color: #cccccc;
            }
            .times {
              width: 80px;
              height: 17px;
              font-size: 12px;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-weight: 500;
              color: #656565;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  .report {
    width: 1202px;
    .breadCrumb {
      display: flex;
      justify-content: space-between;
      .breadCrumb-title {
        height: 22px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      .more {
        height: 20px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #656565;
        line-height: 20px;
      }
    }
    .reportList {
      margin-top: 12px;
      width: 1200px;
      border-top: 1px solid rgba(88, 131, 248, 0.5);
      .point {
        margin-left: 10px;

        width: 8px;
        height: 8px;
        background: #5b84ff;
        border-radius: 4px;
        display: inline-block;
      }
      .reportContent {
        margin-right: 427px;
        margin-left: 12px;
        width: 294px;
        height: 20px;
        line-height: 20px;
      }
      .date {
        width: 64px;
        height: 17px;
      }
      .views {
        width: 80px;
        height: 17px;
        padding-right: 30px;
      }
    }
  }
}
</style>
