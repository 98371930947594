<template>
  <header :class="['page__header-wrapper', { fixed: isFixed }]">
    <div class="header__inner">
      <router-link class="logo" to="/">嘉华大数据中心</router-link>
      <div class="right__nav-wrapper">
        <div class="nav-list">
          <router-link
            :class="[
              'nav-item',
              {
                'is-active': nav.name === selectedNav
              }
            ]"
            v-for="(nav, index) in navList"
            :key="index"
            :to="nav.url"
            >{{ nav.label }}</router-link
          >
        </div>
        <div class="account-info__wrapper">
          <div class="account-info">
            <div class="account-avatar">
              <img :src="accountInfo.avatar" v-if="accountInfo.avatar" />
            </div>
            <span class="account-nickname">{{ accountInfo.name }}</span>
            <span class="dividing-line">|</span>
            <div class="logout" @click="handleLogout">退出</div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { debounce } from "throttle-debounce";
export default {
  props: {
    selectedNav: {
      type: String,
      default: "index"
    }
  },
  data() {
    return {
      isFixed: false,
      navList: [
        {
          label: "首页",
          url: "/",
          name: "index"
        },
        {
          label: "数据资源",
          url: "/data-source",
          name: "data-source"
        },
        {
          label: "数据分析",
          url: "/data-analysis",
          name: "data-analysis"
        },
        {
          label: "典型应用",
          url: "/typical-application",
          name: "typical-application"
        },
        {
          label: "应用学习",
          url: "/applied-learning",
          name: "applied-learning"
        },
        {
          label: "互动交流",
          url: "/interaction",
          name: "interaction"
        }
      ]
      // userInfo: {
      //   nickname: "张三说李四的赵五很六",
      //   avatar:
      //     "https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1977443429,755011870&fm=26&gp=0.jpg"
      // }
    };
  },
  computed: {
    accountInfo() {
      return this.$store.state.accountInfo;
    }
  },
  methods: {
    handleScroll() {
      let currerntScrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let headerHeight = document.querySelector("header").offsetHeight;
      // console.log(currerntScrollTop)
      // console.log(headerHeight)
      this.isFixed = currerntScrollTop >= headerHeight;
    },
    handleLogout: debounce(300, function() {
      this.$confirm("确定要退出吗？", "提示")
        .then(() => {
          this.$api.account.getLogout().then(data => {
            if (data.status == 0) {
              window.location.href = data.redict_url;
              this.$store.dispatch("clearAccountInfo");
            } else {
              this.$message({
                type: "error",
                message: "退出登录失败"
              });
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    })
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style lang="scss" scoped>
.page__header-wrapper {
  background: url("../../assets/images/header_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 70px;
  width: 100%;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;

  &.fixed {
    position: fixed;
  }

  .header__inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .logo {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }

    .nav-list {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;

      .nav-item {
        color: #ffffff;
        font-size: 16px;
        padding: 7px 3px;
        position: relative;

        &:not(:first-of-type) {
          margin-left: 40px;
        }

        &:hover,
        &.is-active {
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 3px;
            height: 2px;
            width: calc(100% - 6px);
            background: #ffffff;
          }
        }
      }
    }

    .account-info__wrapper {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      margin-left: 44px;

      .account-info {
        display: inline-flex;
        align-items: center;

        .account-avatar {
          width: 52px;
          height: 52px;
          background: #ffffff;
          border-radius: 50%;
          display: inline-block;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .account-nickname {
          margin-left: 10px;
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          color: #ffffff;
        }

        .dividing-line {
          margin-left: 19px;
          margin-right: 19px;
          color: #ffffff;
        }

        .logout {
          display: inline-block;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
